.formio-form.formio-read-only {
  .formio-component {
    line-height: 150%;
    margin-bottom: 0;

    > div > p {
      font-size: 16px;
    }

    &.formio-hidden {
      display: none;
    }

    label {
      font-weight: 400;
      padding: 0;
    }
  }
}
