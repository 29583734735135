@import "src/components/FormIOForm/styles/components/checkbox/classes";

.formio-form:not(.formio-read-only) {
  .formio-component-address {
    .checkbox {
      @extend %normal-checkbox;
    }

    input[ref="modeSwitcher"]:checked + span {
      display: inline-block;
      margin-bottom: 1rem;
    }

    .form-group {
      margin-bottom: 1rem;
    }

    .address-autocomplete-container {
      padding-bottom: 1rem;

      & > .form-check-label {
        display: none;
      }
    }
  }
}
