@import "../../foundation/variables";
@import "../../foundation/mixins";

%normal-checkbox {
  // Hide input
  input[type="checkbox"] {
    position: absolute;
    display: block;
    opacity: 0;
  }

  // Text
  input[type="checkbox"] + span {
    font-weight: 400;
    display: inline-flex;
    align-items: flex-start;
  }

  // Checkbox Icon - Default
  input[type="checkbox"] + span::before {
    @include font-icon;

    color: $dark-night;
    content: "\f131";
    cursor: pointer;
    margin-right: 4px;
    text-indent: 12px;
  }

  input[type="checkbox"]:focus-visible + span::before {
    color: #4ca6fd;
    text-shadow: 0 0 1px #005fcc;
  }

  // Checkbox Icon - Checked
  input[type="checkbox"]:checked + span::before {
    content: "\f132";
  }
}

%switch-checkbox {
  // Hide input
  input[type="checkbox"] {
    position: absolute;
    display: block;
    opacity: 0;
  }

  // Label - Default
  input[type="checkbox"] + span {
    color: $dark-neutral;
    display: inline-flex;
    font-weight: 500;
    line-height: 1.4;
    position: relative;
  }

  // Label - Checked
  input[type="checkbox"]:checked + span {
    color: $dark-night;
  }

  // Switch Background - Default
  input[type="checkbox"] + span::before {
    @include font-icon;

    align-items: center;
    background-color: #6c7789;
    border-radius: 9999em;
    color: $white;
    content: "\f156";
    cursor: pointer;
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 12px;
    height: 22px;
    margin-right: 16px;
    text-align: left;
    text-indent: 28px;
    transition: background-color 0.25s ease;
    width: 56px;
  }

  input[type="checkbox"]:focus-visible + span::before {
    box-shadow: 0 0 0 0.2rem #c0d9f1;
  }

  // Switch Background - Checked
  input[type="checkbox"]:checked + span::before {
    background-color: #27ae60;
    content: "\f12c";
    text-indent: 12px;
  }

  // Switch Float - Default
  input[type="checkbox"] + span::after {
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    content: "";
    cursor: pointer;
    height: 18px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: left 0.25s ease;
    width: 18px;
  }

  // Switch Float - Checked
  input[type="checkbox"]:checked + span::after {
    left: 36px;
  }
}
