.formio-form.formio-read-only {
  .formio-component-textfield,
  .formio-component-number,
  .formio-component-currency,
  .formio-component-email,
  .formio-component-phoneNumber,
  .formio-component-textarea,
  .formio-component-address {
    // Layout
    align-items: baseline;
    display: flex;

    & > *:first-child {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
    }

    & > * {
      flex-basis: auto;
      flex-grow: 1;
      flex-shrink: 1;
      margin-right: 0.2rem;
    }

    .wrapper {
      align-items: baseline;
      display: flex;

      & > *:not(:empty) {
        margin-right: 4px;
      }
    }

    &:not(.formio-hidden) {
      margin-bottom: 1.5rem;
    }

    .card-body {
      background: transparent !important;
      border: 0;
      min-height: fit-content;
      padding: 0;
    }

    label {
      font-size: 16px;
    }

    input {
      background: transparent !important;
      border: none;
      font-size: 16px;
      font-weight: 500;
      height: 1rem;
      line-height: 1rem;
      min-width: 0;
      padding: 0;
      width: auto;
    }

    // Empty inputs
    input[size="1"] {
      display: none;
    }

    input[size="1"] + span::before {
      content: "n/a";
      padding-right: 4px;
    }

    // Text area
    .formio-editor-read-only-content {
      font-size: 16px;
      font-weight: 500;
      white-space: pre-line;

      &:empty::before {
        content: "n/a";
      }
    }

    .table {
      border: none;
      width: auto;
    }

    tr {
      display: list-item;
      list-style-position: inside;
    }

    td {
      border: none;
      display: inline-block;
      margin-left: -8px;
      padding: 0;
    }
  }
}
