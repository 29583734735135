.formio-form.formio-read-only {
  .formio-component-selectboxes {
    margin-bottom: 1.5rem;

    & > label {
      margin-bottom: 8px;
      font-size: 16px;
    }

    .radio-option {
      margin-left: 20px;
      margin-bottom: 8px;
    }
  }
}
