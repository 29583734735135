#spreedly-wrapper #payment_div {
    min-width: 600px;
}

#spreedly-wrapper .spreedly-sidebar {
    background-color: #f5f5f5;
    border:none;
}

#spreedly-wrapper .spreedly-sidebar-content {
    max-width: 198px;
    min-width: 198px;
}

#spreedly-wrapper .spreedly-sidebar-divider {
    margin: 15px 0;
    background-color: transparent;
}

#spreedly-wrapper .spreedly-background {
    background-color: #ffffff;
}

#spreedly-submit-button {
    background-color: #0266C8;
    box-shadow: none;
}

#spreedly-wrapper #spreedly-amount {
    color: #0266C8;
}

#spreedly-wrapper #spreedly-modal-form .spreedly-label,
#spreedly-wrapper h3,
#spreedly-wrapper .sidebar-top-description,
#spreedly-wrapper .sidebar-bottom-description {
    color: #525965;
}


#spreedly-wrapper h1 {
    color: #32373F;
}

#spreedly-wrapper #spreedly-sidebar-bottom-description,
#spreedly-wrapper #spreedly-sidebar-top-description {
    font-size: 14px;
    line-height: 18px;
}