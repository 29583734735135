@import "src/components/FormIOForm/styles/foundation/variables";

.formio-form:not(.formio-read-only) {
  .formio-component-columns {
    & > *:first-child {
      padding-left: 0;
    }

    // Workaround: the last child of Columns is div.formio-errors
    & > *:nth-last-child(2) {
      padding-right: 0;
    }
  }
}
