@import "src/components/FormIOForm/styles/foundation/variables";

.formio-form.formio-read-only {
  .formio-component-select {
    // Layout
    display: flex;
    margin-bottom: 1.5rem;

    label {
      margin-right: 0.2rem;
      font-size: 16px;
    }

    // Wrapper
    .formio-choices {
      border: none;
      display: inline-block;
      margin: 0;
      min-width: 0;
    }

    .form-control {
      border: none;
      height: auto;
      padding: 0;
    }

    // Hide input
    .choices__input {
      display: none;
    }

    // Choice item
    .choices__item {
      background: none;
      border: none;
      color: $dark-night;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.4;
      margin: 0;
      padding: 0;
    }

    // Multiple choices
    .choices__list--multiple .choices__item {
      display: list-item;
      list-style-position: inside;
      list-style-type: disc;
      margin: 0;

      & > *:first-child {
        margin-left: -8px;
      }
    }
  }
}