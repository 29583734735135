@import "src/components/FormIOForm/styles/foundation/mixins";

.fa {
  @include font-icon;
}

.fa-times-circle-o::before {
  content: "\f15a";
}

.fa-question-circle::before {
  content: "\f2d7";
}

.fa-calendar::before {
  content: "\f0ed";
}
