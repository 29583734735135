@media print {

  @page {
    size: a3 portrait;
    margin: 1cm;
  }

  body {
    overflow: hidden; /* Hide scrollbars */
    padding: 0;
    margin: 0;
  }

  /* Force browser to include Background Graphics */
  * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
  }
}