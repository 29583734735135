@import "src/components/FormIOForm/styles/foundation/variables";
@import "src/components/FormIOForm/styles/foundation/mixins";

.formio-form.formio-read-only {
  .formio-component-radio {
    // Layout
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;

    // Hide input
    input[type="radio"] {
      display: none;
    }

    // Question Label
    label {
      color: $dark-neutral;
      display: inline-block;
      font-size: 16px;
      margin-right: 0.2rem;

      > .radio-option {
        color: $dark-night;
        display: inline-block;
      }
    }

    // Option Label - Default
    input[type="radio"] + span {
      display: none;
    }

    // Option Label - Checked
    input[type="radio"]:checked + span {
      display: inline-block;
      font-weight: 500;
    }
  }
}
