.formio-form.formio-read-only {
  .formio-component-address {
    // Hide mode switcher
    div.form-check.checkbox {
      display: none;
    }

    // Clear empty spaces
    .address-autocomplete-container {
      margin: 0;
      padding: 0;

      & > .form-check-label {
        font-size: 16px;
      }

      & > input {
        display: inline;
        font-size: 16px;
      }
    }

    // List items for manual address inputs
    div[ref^="nested-"] > * {
      margin-bottom: 1.5rem;
    }
  }
}
