@import "../../foundation/variables";
@import "../../foundation/mixins";

%toggle-buttons {

  // Radio button title
  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: 14px;
    line-height: 24px;
    color: $dark-neutral;
    font-weight: 700;
  }

  // Radio group
  .form-radio {
    display: flex;
    width: 100%;

    // Radio button box
    .form-check {
      white-space: nowrap;
      flex: 1 1 100%;
      background-color: $white;
      border-top: 1px solid $light-allspice;
      border-bottom: 1px solid $light-allspice;
      border-right: 1px solid $light-allspice;

      .form-check-label {
        cursor: pointer;
        text-align: center;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        font-weight: 500;
        color: $dark-fiordland;
      }
    }

    .form-check:first-child {
      border-left: 1px solid $light-allspice;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .form-check:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .form-check.radio-selected {
      transition: 0.13s ease-in-out;
      background-color: $blue;

      .form-check-label {
        color: $white;
        width: auto;
        padding-left: 6px;
        text-align: left;
      }
    }

    .form-check.radio-selected::before {
      font-family: "Objective" !important;
      padding-left: 14px;
      color: white;
      content: "\f12c";
    }

    // Hide Radio button
    .form-check-input {
      visibility: visible;
      opacity: 0;
      position: absolute;
    }
  }
}