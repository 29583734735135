@import "node_modules/bootstrap/scss/mixins";

// Overwrite bootstrap mixins
@mixin make-container($gutter: 0) {
  width: 100%;
  padding-right: $gutter / 2;
  padding-left: $gutter / 2;
  margin-right: auto;
  margin-left: auto;
}

@mixin make-row($gutter: 0) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$gutter / 2;
  margin-left: -$gutter / 2;
}

// Custom mixins
@mixin font-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Objective" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
