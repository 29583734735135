@import "src/components/FormIOForm/styles/foundation/variables";
@import "src/components/FormIOForm/styles/components/checkbox/classes";

.formio-form:not(.formio-read-only) {
  .formio-component-selectboxes {
    .radio-option {
      margin-bottom: 0.5rem;
    }
  }

  .formio-component-selectboxes:not(.formio-component-label-hidden) {
    @extend %switch-checkbox;
  }

  .formio-component-selectboxes.formio-component-label-hidden {
    @extend %normal-checkbox;
  }
}