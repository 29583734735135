@import "src/components/FormIOForm/styles/foundation/variables";
@import "src/components/FormIOForm/styles/foundation/mixins";

.formio-form.formio-read-only {
  .formio-component-checkbox {
    // Layout
    .checkbox {
      margin-bottom: 1.5rem;
    }

    // Label
    .form-check-label {
      color: $dark-neutral;
      font-size: 16px;
    }

    // Hide input
    input[type="checkbox"] {
      display: none;
    }

    // Text indicator - Default
    input[type="checkbox"] + span::after {
      color: $dark-night;
      content: "No";
      font-weight: 500;
    }

    // Text indicator - Checked
    input[type="checkbox"]:checked + span::after {
      color: $dark-night;
      content: "Yes";
    }
  }
}
