@import "src/components/FormIOForm/styles/foundation/variables";

legend {
  color: $dark-neutral;
  font-weight: bold;
  margin-bottom: $spacer * 4;
}

.formio-read-only legend {
  font-size: 16px;
}

p {
  color: $dark-neutral;
  line-height: 1.5;
}

.react-component p {
  line-height: inherit;
  margin-top: inherit;
  margin-bottom: inherit;
}