@import "src/components/FormIOForm/styles/foundation/variables";

// Custom classes

// - injected-data

.injected-data {
  display: none !important;
}

// - only-in-review
.only-in-review {
  display: none !important;
}

.formio-read-only .only-in-review {
  display: block !important;
}

// - hide-in-review
.hide-in-review {
}

.formio-read-only .hide-in-review {
  display: none !important;
}

// - help-text
.help-text {
  border-left-color: $light-allspice;
  border-left-style: solid;
  border-left-width: 1px;
  padding-bottom: $spacer * 3;
  padding-left: $spacer * 5;
  padding-top: $spacer * 3;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $dark-neutral;
  }
}

.help-link {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: $link-color;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
    cursor: pointer;
  }
}

.formio-read-only .help-text {
  display: none;
}

// - large-label
.large-label {
  .col-form-label {
    color: $dark-neutral;
    font-size: 22px;
    font-weight: bold;
    padding-bottom: $spacer * 6;
  }
}
