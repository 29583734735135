@import "src/components/FormIOForm/styles/foundation/variables";

label {
  color: $dark-neutral;
  font-weight: bold;
  padding-top: 0;
}

label.field-required::after {
  z-index: 1;
}
