@import "src/components/FormIOForm/styles/foundation/variables";
@import "src/components/FormIOForm/styles/foundation/mixins";
@import "radio.classes";

.formio-form:not(.formio-read-only) {
  .formio-component-radio:not(.yes-no-buttons) {
    // Layout
    .radio-option {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    // Hide input
    input[type="radio"] {
      position: absolute;
      display: block;
      opacity: 0;
    }

    // Option Label - Default
    input[type="radio"] + span {
      color: $dark-neutral;
      cursor: pointer;
      font-weight: 500;
    }

    // Option Label - Checked
    input[type="radio"]:checked + span {
      color: $dark-night;
    }

    // Icon - Default
    input[type="radio"] + span::before {
      @include font-icon;

      color: $dark-night;
      content: "\f43d";
      cursor: pointer;
      margin-right: 4px;
      text-indent: 12px;
    }

    input[type="radio"]:focus-visible + span::before {
      color: #005fcc;
    }

    // Icon - Checked
    input[type="radio"]:checked + span::before {
      content: "\f43e";
    }
  }

  .formio-component-radio.yes-no-buttons {
    @extend %toggle-buttons;

    .form-radio {
      width: 168px;
    }
  }
}
