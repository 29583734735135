@import "variables";

.formio-read-only {
  @each $breakpoint, $width in $grid-breakpoints {
    @for $i from 1 through $grid-columns {
      .col-#{$breakpoint}-#{$i} {
        min-width: 100%;
        max-width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        padding: 0;
      }
    }
  }
}
