@import "src/components/FormIOForm/styles/foundation/variables";

fieldset .form-group,
.card-body .form-group {
  margin-bottom: $spacer * 4;
}

.form-group {
  margin-bottom: $spacer * 8;

  &.formio-hidden {
    margin-bottom: 0;
  }

  &.formio-choices {
    margin-bottom: 0;
  }
}